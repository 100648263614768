import { useState } from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import TestimonialCard from './TestimonialCard/TestimonialCard';
import s from './testimonials.module.scss';
import classes from './sliderStyles.module.scss';
import { useMediaQuery } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import '@mantine/carousel/styles.css';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';

export type Testimonial = {
  card_image: string;
  card_productImage: string;
  tr_card_description: string;
  review_rating: number;
  card_author: string;
  video_id: string;
  id?: string;
};

type Props = {
  children: string | JSX.Element | JSX.Element[] | boolean;
};

const TestimonialsSection = ({ children }: Props) => {
  const [playingCardId, setPlayingCardId] = useState<string | null>(null);
  const { videoTestimonials } = usewpdataSelectors();
  const handlePlayPause = (id: string | null) => {
    setPlayingCardId(id);
  };
  const theme = useMantineTheme();
  const mobile: boolean | undefined = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <div className={s.testimonialsContainer} style={{ background: videoTestimonials.styles.background }}>
      <div className={s.testimonialsContainer__wrapper}>
        <SectionTitle
          type="section"
          heading={videoTestimonials.title}
          subheading={`${videoTestimonials.rating} | ${videoTestimonials.ratingText}`}
          rating={videoTestimonials.rating}
        />
        <div className={s.testimonialsContainer__sliderWrapper}>
          <Carousel
            classNames={classes}
            withIndicators={false}
            slideGap="md"
            loop={true}
            initialSlide={0}
            align={mobile ? 'center' : 'start'}
            slideSize={mobile ? '72%' : '25%'}
            onSlideChange={() => setPlayingCardId(null)}
          >
            {videoTestimonials.testimonials.map((item, index) => {
              return (
                <Carousel.Slide key={index}>
                  <TestimonialCard
                    onPlay={handlePlayPause}
                    isPlaying={playingCardId === item.video_id}
                    verifiedText={videoTestimonials.verifiedText}
                    verifyIconUrl={videoTestimonials.verifyIcon}
                    {...item}
                  />
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </div>
        {children}
      </div>
    </div>
  );
};

export default TestimonialsSection;
