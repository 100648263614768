import { useState, useRef, useLayoutEffect } from 'react';
import s from './customerReviews.module.scss';
import Statistics from './Statistics';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import MasonryItem from './ReviewItem/MasonryItem';
import ReviewItem from './ReviewItem/ReviewItem';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import SectionTitle from 'components/SectionTitle/SectionTitle';

export default function CustomerReviews({ listStyle = 'normal' }: { listStyle?: string }) {
  const [showAllReviews, setShowAllReviews] = useState(false);
  const { customerReviewsBlock } = usewpdataSelectors();
  const [visibleReviews, setVisibleReviews] = useState(customerReviewsBlock.loadSlides);
  const [containerHeight, setContainerHeight] = useState(0);
  const reviewsWrapperRef = useRef(null);

  useLayoutEffect(() => {
    if (reviewsWrapperRef?.current) {
      const height = reviewsWrapperRef?.current?.scrollHeight;
      setContainerHeight(height + 2);
    }
  }, [showAllReviews, visibleReviews]);

  const handleToggleReviews = () => {
    if (showAllReviews) {
      setVisibleReviews(customerReviewsBlock.loadSlides);
    } else {
      setVisibleReviews(customerReviewsBlock.reviews.length);
    }
    setShowAllReviews(!showAllReviews);
  };
  return (
    <section id="reviews" className={s.customerReviews} style={{ background: customerReviewsBlock.styles.background }}>
      <div className={s.customerReviews__wrapper}>
        <SectionTitle heading={customerReviewsBlock.title} subheading={customerReviewsBlock.subtitle} type="section" />
        <Statistics />
        {listStyle === 'masonry' ? (
          <div
            className={`${s.masonryReviews} ${showAllReviews ? s.expanded : s.collapsed}`}
            data-expanded={showAllReviews}
            style={{ height: `${containerHeight}px` }}
          >
            <div ref={reviewsWrapperRef} style={{ position: 'relative' }}>
              <ResponsiveMasonry columnsCountBreakPoints={{ 0: 2, 700: 4 }}>
                <Masonry gutter="8px">
                  {customerReviewsBlock?.reviews &&
                    customerReviewsBlock.reviews
                      .slice(0, visibleReviews)
                      .map((review, index) => <MasonryItem key={index} review={review} />)}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </div>
        ) : (
          <>
            {customerReviewsBlock?.reviews &&
              customerReviewsBlock.reviews
                .slice(0, visibleReviews)
                .map((item, index) => <ReviewItem key={index} review={item} />)}
          </>
        )}
        <button
          style={{
            color: customerReviewsBlock.styles.buttonColor,
            borderColor: customerReviewsBlock.styles.buttonColor,
          }}
          className={s.ctaButton}
          onClick={handleToggleReviews}
        >
          {showAllReviews ? customerReviewsBlock.showLessLabel : customerReviewsBlock.showMoreLabel}
        </button>
      </div>
    </section>
  );
}
