import { ChevronDown } from 'components/SharedIcons/ChevronDown';
import './selectCard.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { Group, Select, SelectProps } from '@mantine/core';

const renderSelectOption: SelectProps['renderOption'] = ({ option, checked }) => {
  return (
    <Group
      flex="1"
      gap="xs"
      className="mantine-Select-option-hover"
      style={{ background: checked ? '#f0f0f0' : '#fff', padding: '16px' }}
    >
      {option.label}
    </Group>
  );
};

const selectStyles = {
  input: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '2px solid #45050C',
    padding: '26px 16px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#45050C',
  },
  option: {
    padding: '0px',
  },
  dropdown: { maxHeight: 200, overflowY: 'auto', padding: '0px' },
};

const selectCard = ({ options, handleClick }: SelectCardDropdownProps) => {
  const { hero } = usewpdataSelectors();

  console.log(hero);

  const mappedOptions =
    options &&
    options.map((optionItem) => {
      return {
        value: optionItem?.id,
        label: optionItem?.tr_title,
      };
    });

  const defaultValue =
    options &&
    options.find((optionItem) => {
      return optionItem.default_option === true;
    });

  return (
    <Select
      withCheckIcon={true}
      checkIconPosition="right"
      checked={true}
      defaultValue={defaultValue?.id}
      styles={selectStyles}
      data={mappedOptions}
      renderOption={renderSelectOption}
      allowDeselect={false}
      rightSection={<ChevronDown className={open ? 'rotate-up' : ''} fill={hero?.styles?.textColorPrimary} />}
      onChange={(e) => {
        const findItem = options.find((optionItem) => optionItem.id === e);
        handleClick({
          id: e,
          sku: findItem?.sku,
          label: findItem?.tr_title,
          price: findItem?.price,
          old_price: findItem?.old_price,
          konnektive_id: findItem?.konnektive_id,
        });
      }}
    />
  );
};

interface SelectCardDropdownProps {
  handleClick: (
    id: string,
    sku: string,
    label: string,
    price: number,
    old_price: number,
    konnektive_id: string
  ) => void;
  options: {
    id: number;
    display_name: string;
    tr_additional_texts: string;
    price: number;
    old_price: number;
    status?: string;
    image: string;
    default_option: boolean;
    tr_title: string;
  }[];
}

export default selectCard;
