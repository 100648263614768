import React from 'react';
import '../QuantityCards.module.scss';
import { decode } from 'html-entities';

type VariantOptionProps = {
  type: string;
  label: string;
  color?: string;
  size?: string;
  value: string;
  currentSelect: string;
  handleClick: EventListener;
};

const VariantOption = ({ type, label, color, value, currentSelect, handleClick }: VariantOptionProps) => {
  const currentlySelectedValue = type === 'color' ? currentSelect?.color : currentSelect?.size;

  return (
    <li
      className={`custom-select--item ${currentlySelectedValue === value && 'custom-select--item--active'}`}
      onClick={() => {
        handleClick(type, value, label);
      }}
    >
      {type == 'color' && (
        <div className="custom-select__colorIndicator" style={{ background: color ? color : value }}></div>
      )}
      <p dangerouslySetInnerHTML={{ __html: decode(label) }}></p>
    </li>
  );
};

export default VariantOption;
