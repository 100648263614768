import React, { useEffect } from 'react';
import './index.css';
import './styles/styles.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { Cart } from './components/Cart/Cart';
import { useInView } from 'react-intersection-observer';
import TestimonialsSection from './components/VideoTestimonials/Testimonials';
import { Header } from './components/Header/Header';
import Hero from './components/Hero/Hero';
import CustomerReviews from './components/CustomerReviews';
import LogoSlider from './components/LogoSlider/LogoSlider';
import TestimonialSlides from './components/TestimonialsSwiper/TestimonialSwiper';
import ProductSection from './components/ProductsSection/ProductSection';
import FeatesList from 'components/FeaturesList/FeaturesList';
import Setup from 'components/ProductSetup/Setup';
import { SlidingTextBar } from './components/SlidingTextBar/SlidingTextBar';
import Footer from 'components/Footer/Footer';
import FloatingCTA from 'components/Floater/Floater';
import { FAQ } from 'components/FAQ/FAQ';
import Comparison from 'components/Comparison/Comparison';
import QuoteBlock from 'components/BoastBlock/QuoteBlock';
import ExpertBlock from 'components/BoastBlock/ExpertBlock';
import CompetitorsComparison from 'components/CompetitorsComparison/CompetitorsComparison';
import ImageSlider from 'components/VideoTestimonials/ImageSlider/ImageSlider';
import ProductHero from 'components/ProductHero/ProductHero';
import UpsellModal from 'components/UpsellModal/UpsellModal';
import Support from 'components/Zendesk';

const Layout = () => {
  const { Layout, Theme } = usewpdataSelectors();
  const { ref: heroRef, inView: isHeroVisible } = useInView({
    threshold: 0.1,
  });
  const { ref: headerRef, inView: isHeaderVisible } = useInView({
    threshold: 0.01,
  });

  console.log(isHeaderVisible);

  return (
    <>
      <div
        style={{
          '--button-custom-background': Theme?.customButton?.background,
          '--button-custom-backgroundHover': Theme?.customButton?.backgroudHover,
          '--button-custom-borderColor': Theme?.customButton?.borderColor,
          '--button-custom-textColor': Theme?.customButton?.textColor,
        }}
      >
        {Layout.hero?.layout !== 'lander' && <Cart />}
        <UpsellModal />
        <div ref={headerRef}>
          <Header />
        </div>
        {Layout?.hero?.display && (
          <div ref={heroRef}>
            {Layout.hero?.layout === 'lander' ? <Hero /> : <ProductHero isHeaderVisible={isHeaderVisible} />}
          </div>
        )}
        {Layout?.featuredIn?.display && <LogoSlider />}
        {Layout?.products?.display && <ProductSection />}
        {Layout?.videoTestimonials?.display && (
          <TestimonialsSection>{Layout?.videoTestimonialsImages?.display && <ImageSlider />}</TestimonialsSection>
        )}
        {Layout?.baComparison?.display && <Comparison />}
        {Layout?.features?.display && <FeatesList />}
        {Layout?.setup?.display && <Setup />}
        {Layout?.ribbon?.display && <SlidingTextBar />}
        {Layout?.testimonialsSlider?.display && <TestimonialSlides />}
        {Layout?.competitorsComparison?.display && <CompetitorsComparison />}
        {Layout?.faq?.display && (
          <FAQ>
            {Layout.awards.display && <QuoteBlock />}
            {Layout.expert.display && <ExpertBlock />}
          </FAQ>
        )}
        {Layout?.reviews?.display && <CustomerReviews listStyle={Layout?.reviews?.layout || 'masonry'} />}
        <Footer />
        {Layout?.floater?.display && <FloatingCTA isHeroVisible={isHeroVisible} />}
        <div>
          <Support isHeroVisible={isHeroVisible} floaterVisible={Layout.floater?.display} />
        </div>
      </div>
    </>
  );
};

export default Layout;
