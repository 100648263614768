import { productType, upsellItem, bundleType } from 'lib/productsState/productsTypes';

export const calculateMainTotals = (product: productType, bundle: bundleType[]) => {
  const bundleItemsPrice = bundle ? bundle.reduce((acc, item) => acc + Number(item?.price), 0) : 0;
  const bundleItemsOldPrice = bundle ? bundle.reduce((acc, item) => Number(acc) + Number(item?.old_price), 0) : 0;
  const mainProductPrice = Number(product.price) + Number(bundleItemsPrice);
  const mainProductOldPrice = Number(product.old_price) + Number(bundleItemsOldPrice);
  const mainProductAmount = Number(product.amount);

  const mainTotal = mainProductPrice * mainProductAmount;
  const mainSavings = (mainProductOldPrice - mainProductPrice) * mainProductAmount;
  return { mainTotal, mainSavings };
};

export const calculateUpsellsTotals = (upsells: upsellItem[]) => {
  let upsellsTotal = 0;
  let upsellsSavings = 0;

  upsells.forEach((upsell) => {
    const bundleItemsPrice = upsell.bundle ? upsell.bundle.reduce((acc, item) => acc + Number(item?.price), 0) : 0;
    const bundleItemsOldPrice = upsell.bundle
      ? upsell.bundle.reduce((acc, item) => Number(acc) + Number(item?.old_price), 0)
      : 0;

    const upsellPrice = Number(upsell.product.price) + Number(bundleItemsPrice);
    const upsellOldPrice = Number(upsell.product.old_price) + Number(bundleItemsOldPrice);
    const upsellAmount = Number(upsell.product.amount);

    upsellsTotal += upsellPrice * (upsellAmount || 1);
    upsellsSavings += (upsellOldPrice - upsellPrice) * (upsellAmount || 1);
  });

  return { upsellsTotal, upsellsSavings };
};

export const groupMainProductByVariants = (mainProduct) => {
  const groupedData = mainProduct.variants.reduce((acc, curr) => {
    const key = `${curr?.size || ''}-${curr?.color || ''}`;
    if (!acc[key]) {
      acc[key] = {
        amount: 0,
        display_name: mainProduct.product.display_name,
        description:
          curr?.colorLabel && curr?.sizeLabel
            ? `${curr.colorLabel} / ${curr.sizeLabel}`
            : curr?.colorLabel || curr?.sizeLabel || mainProduct.product.tr_description,
        price: mainProduct.product.price,
        image: curr?.colorImage ? curr?.colorImage : mainProduct.image?.sizes?.thumbnail,
        old_price: mainProduct.product.old_price,
        id: curr?.id,
        parentId: mainProduct.product.id,
      };
    }

    acc[key].amount++;
    return acc;
  }, {});

  // Sort the grouped data alphabetically by 'id'
  return Object.values(groupedData).sort((a, b) => a.id.localeCompare(b.id));
};

export const consolidateVariants = (
  variants: any[],
  ProductColorVariants: any[] = [],
  ProductSizeVariants: any[] = []
) => {
  const variantMap: { [key: string]: { quantity: number; colorVariant: any; sizeVariant: any } } = {};

  variants.forEach((variant: { color: any; colorLabel: string | number; size: string; sizeLabel: string | number }) => {
    const colorVariant = (ProductColorVariants || []).find(
      (cvariant: { value: any }) => cvariant.value === variant.color
    );

    const sizeVariant = (ProductSizeVariants || []).find((svariant: { value: any }) => svariant.value === variant.size);

    const variantKey = `${variant.colorLabel}-${variant.sizeLabel}`;

    if (variantMap[variantKey]) {
      variantMap[variantKey].quantity += 1;
    } else {
      variantMap[variantKey] = {
        quantity: 1,
        colorVariant,
        sizeVariant,
      };
    }
  });

  // Map the consolidated variants into the desired structure
  return Object.entries(variantMap).map(([key, { quantity, colorVariant, sizeVariant }]) => ({
    colorLabel: colorVariant?.tr_label ? colorVariant?.tr_label || key.split('-')[0] : undefined, // Use color label from color variant or fallback to part of key
    sizeLabel: sizeVariant?.tr_label ? sizeVariant?.tr_label || key.split('-')[1] : undefined, // Use size label from size variant or fallback to part of key
    quantity,
    colorVariant,
    sizeVariant,
  }));
};
