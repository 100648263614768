import React from 'react';
import { useCartSelectors } from 'lib/cartState/selectors';
import CartUpsellCard from '../CartUpsellCard/CartUpsellCard';

const VariantUpsells = () => {
  const { cartProducts } = useCartSelectors();

  if (!cartProducts.upsells.length) {
    return null;
  }

  const groupedUpsells = cartProducts.upsells
    .filter((item) => item.product.enable_color_variants === true || item.product.size_variants === true)
    .map((upsellProduct) => {
      const { product, variants } = upsellProduct;
      const groupedData =
        variants &&
        variants.reduce((acc, curr) => {
          const key = `${curr?.size || ''}-${curr?.color || ''}`;

          if (!acc[key]) {
            acc[key] = {
              amount: 0,
              display_name: product.tr_display_name,
              description:
                curr?.colorLabel && curr?.sizeLabel
                  ? `${curr.colorLabel} / ${curr.sizeLabel}`
                  : curr?.colorLabel || curr?.sizeLabel || product.tr_description,
              price: product.price,
              image: curr?.color_image ?? product.product_image,
              old_price: product.old_price,
              id: curr?.id,
              parentId: product.id,
            };
          }

          acc[key].amount++;
          return acc;
        }, {});
      return { product, groupedVariants: Object.values(groupedData) };
    });

  return (
    <>
      {groupedUpsells.map((upsellItem, upsellIndex) => (
        <React.Fragment key={upsellItem.product.id || upsellIndex}>
          {upsellItem.groupedVariants.map((upsellProduct, variantIndex) => {
            return (
              <CartUpsellCard
                key={upsellProduct.id || variantIndex}
                variant={[]}
                cartItem={upsellProduct}
                totalQty={upsellItem.product.amount}
                type="variant"
              />
            );
          })}
        </React.Fragment>
      ))}
    </>
  );
};

export default VariantUpsells;
